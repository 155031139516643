import $ from "jquery";
import Typed from "typed.js";

// Typed animation
$(function () {
  const id = "#typed";
  const $typed = $(id);
  const strings = $typed.attr("data-strings");

  if (strings) {
    new Typed(id, {
      strings: strings.split(","),
      backSpeed: 60,
      typeSpeed: 120,
      loop: true,
      showCursor: true,
      backDelay: 2000,
    });
  }
});
